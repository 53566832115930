<template>
  <div id="hzkTP">
    <!-- 非移动端展示 -->
    <div class="containT small_none">
      <div style="display:flex">
        <!-- <div class="top_logo" @click="tohome2">
          <img src="../assets/img/logo.svg" alt="" >
        </div>-->
        <div class="top_logo_png" @click="tohome2">
          <img src="../assets/img/logo.svg" alt />
        </div>
        <!-- <div class="myImg" @click="tohome" />
        <div class="myLogo"></div>
        <div class="myImg2" @click="tohome2" />-->
        <ul>
          <li
            v-for="(item, index) in list"
            :key="index"
            @click="linkTo(index)"
            :style="item.path === pathname ? 'color: #007AFF;' : ''"
          >
            <div :class="{ active: item.path === pathname }"></div>
            {{ item.name }}
          </li>
        </ul>
      </div>

      <div class="tRight">
        <!-- <el-menu :default-active="this.$route.path" class="el-menu-demo" mode="horizontal"
                active-text-color="rgb(0, 121, 254)" text-color="#999999" background-color="#fff" router>
                <el-menu-item :index="item.path" v-for="(item, index1) in list" :key="index1">{{ item.name }}
                </el-menu-item>
        </el-menu>-->
        <div class="login small_none">
          <div
            class="l1"
            v-if="$store.state.login.token === '' && $store.state.login.token2 === ''"
          >
            <span @click="registerImmediately">求职者注册</span>
            <span @click="rCenroll">用人单位注册</span>
            <button @click="loginImmediately">登录</button>

            <!-- <button @click="login">登录</button>
            <button @click="enroll">注册</button>-->
          </div>
          <div class="islogin" v-else @mouseleave="show = false">
            <div @mouseenter="show = true">
              <span>您好，{{ name }}</span>
              <i class="el-icon-arrow-down"></i>
            </div>
            <!--  求职者-->
            <template v-if="userType == 1">
              <div class="menu" v-if="show">
                <ul>
                  <li @click="JobenterCenter(0)">
                    <i class="fa fa-user" aria-hidden="true"></i>
                    用户中心
                  </li>
                  <li @click="JobenterCenter(1)">
                    <i class="fa fa-star" aria-hidden="true"></i>
                    我的收藏
                  </li>
                  <li @click="JobenterCenter(2)">
                    <i class="fa fa-cog" aria-hidden="true"></i>
                    账号设置
                  </li>
                  <li @click="JobenterCenter(3)">
                    <i class="fa fa-sign-out" aria-hidden="true"></i>
                    退出登录
                  </li>
                </ul>
              </div>
            </template>
            <!--    招聘方-->
            <template v-else>
              <div class="menu" v-if="show">
                <ul>
                  <li @click="RCenterCenter(0)">
                    <i class="fa fa-user" aria-hidden="true"></i>
                    用户中心
                  </li>
                  <li @click="RCenterCenter(1)">
                    <i class="fa fa-paper-plane" aria-hidden="true"></i>
                    我的发布
                  </li>
                  <li @click="RCenterCenter(2)">
                    <i class="fa fa-cog" aria-hidden="true"></i>
                    账号设置
                  </li>
                  <li @click="RCenterCenter(3)">
                    <i class="fa fa-sign-out" aria-hidden="true"></i>
                    退出登录
                  </li>
                </ul>
              </div>
            </template>
          </div>
        </div>
        <!--      <div v-if="!flag" class="obtain_points" @click="obtainIntegral">领取积分</div>-->
        <!--      <div v-else class="obtain_points" @click="alreadyObtain">今日已领取</div>-->
        <div class="login small_block">
          <div class="flex">
            <div>
              <div class="login">
                <div
                  class="l1"
                  v-if="$store.state.login.token === '' && $store.state.login.token2 === ''"
                >
                  <span @click="registerImmediately">求职者注册</span>
                  <span @click="rCenroll">用人单位注册</span>
                  <button @click="loginImmediately">登录</button>

                  <!-- <button @click="rCenroll">我要招聘</button>
                  <div style="margin: auto 0;cursor:pointer;display: flex;">
                    <img src="../assets/img/login_icon.svg" alt="" width="32px" @click="loginImmediately">
                    <img src="../assets/img/Line 11.svg" alt="" style="margin: 0 13px;" width="1px">
                    <img src="../assets/img/zhuce_icon.svg" alt="" width="32px" @click="registerImmediately">
                  </div>-->
                  <!-- <button @click="login">登录</button>
                  <button @click="enroll">注册</button>-->
                </div>
                <div class="islogin" v-else @mouseleave="show = false">
                  <div @mouseenter="show = true">
                    <span>您好，{{ name }}</span>
                    <i class="el-icon-arrow-down"></i>
                  </div>
                  <!--  求职者-->
                  <template v-if="userType == 1">
                    <div class="menu" v-if="show">
                      <ul>
                        <li @click="JobenterCenter(0)">
                          <i class="fa fa-user" aria-hidden="true"></i>
                          用户中心
                        </li>
                        <li @click="JobenterCenter(1)">
                          <i class="fa fa-star" aria-hidden="true"></i>
                          我的收藏
                        </li>
                        <li @click="JobenterCenter(2)">
                          <i class="fa fa-cog" aria-hidden="true"></i>
                          账号设置
                        </li>
                        <li @click="JobenterCenter(3)">
                          <i class="fa fa-sign-out" aria-hidden="true"></i>
                          退出登录
                        </li>
                      </ul>
                    </div>
                  </template>
                  <!--    招聘方-->
                  <template v-else>
                    <div class="menu" v-if="show">
                      <ul>
                        <li @click="RCenterCenter(0)">
                          <i class="fa fa-user" aria-hidden="true"></i>
                          用户中心
                        </li>
                        <li @click="RCenterCenter(1)">
                          <i class="fa fa-paper-plane" aria-hidden="true"></i>
                          我的发布
                        </li>
                        <li @click="RCenterCenter(2)">
                          <i class="fa fa-cog" aria-hidden="true"></i>
                          账号设置
                        </li>
                        <li @click="RCenterCenter(3)">
                          <i class="fa fa-sign-out" aria-hidden="true"></i>
                          退出登录
                        </li>
                      </ul>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <!-- <div class="border_wrap" @click="showNuem">
              <div class="border"></div>
              <div class="border"></div>
              <div class="border"></div>
            </div>-->
          </div>
        </div>
      </div>
    </div>
    <!-- 移动端展示 -->
    <div class="top_small_block small_block">
      <div class="top_wrap">
        <div style="display:flex">
          <div class="top_logo_png" @click="tohome2">
            <img src="../assets/img/IMAGE.png" alt />
          </div>
        </div>

        <div class="tRight">
          <!-- <el-menu :default-active="this.$route.path" class="el-menu-demo" mode="horizontal"
                  active-text-color="rgb(0, 121, 254)" text-color="#999999" background-color="#fff" router>
                  <el-menu-item :index="item.path" v-for="(item, index1) in list" :key="index1">{{ item.name }}
                  </el-menu-item>
          </el-menu>-->
          <div class="login">
            <div
              class="l1"
              v-if="$store.state.login.token === '' && $store.state.login.token2 === ''"
            >
              <span @click="registerImmediately">求职者注册</span>
              <span @click="rCenroll">用人单位注册</span>
              <button @click="loginImmediately">登录</button>

              <!-- <button @click="login">登录</button>
              <button @click="enroll">注册</button>-->
            </div>
            <div class="islogin" v-else @mouseleave="show = false">
              <div @mouseenter="show = true">
                <span>您好，{{ name }}</span>
                <i class="el-icon-arrow-down"></i>
              </div>
              <!--  求职者-->
              <template v-if="userType == 1">
                <div class="menu" v-if="show">
                  <ul>
                    <li @click="JobenterCenter(0)">
                      <i class="fa fa-user" aria-hidden="true"></i>
                      用户中心
                    </li>
                    <li @click="JobenterCenter(1)">
                      <i class="fa fa-star" aria-hidden="true"></i>
                      我的收藏
                    </li>
                    <li @click="JobenterCenter(2)">
                      <i class="fa fa-cog" aria-hidden="true"></i>
                      账号设置
                    </li>
                    <li @click="JobenterCenter(3)">
                      <i class="fa fa-sign-out" aria-hidden="true"></i>
                      退出登录
                    </li>
                  </ul>
                </div>
              </template>
              <!--    招聘方-->
              <template v-else>
                <div class="menu" v-if="show">
                  <ul>
                    <li @click="RCenterCenter(0)">
                      <i class="fa fa-user" aria-hidden="true"></i>
                      用户中心
                    </li>
                    <li @click="RCenterCenter(1)">
                      <i class="fa fa-paper-plane" aria-hidden="true"></i>
                      我的发布
                    </li>
                    <li @click="RCenterCenter(2)">
                      <i class="fa fa-cog" aria-hidden="true"></i>
                      账号设置
                    </li>
                    <li @click="RCenterCenter(3)">
                      <i class="fa fa-sign-out" aria-hidden="true"></i>
                      退出登录
                    </li>
                  </ul>
                </div>
              </template>
            </div>
          </div>
          <!--      <div v-if="!flag" class="obtain_points" @click="obtainIntegral">领取积分</div>-->
          <!--      <div v-else class="obtain_points" @click="alreadyObtain">今日已领取</div>-->
        </div>
      </div>
      <div class="bottom_wrap">
        <ul>
          <li
            v-for="(item, index) in list"
            :key="index"
            @click="linkTo(index)"
            :style="item.path === pathname ? 'color: #007AFF;' : ''"
          >
            {{ item.name }}
            <div :class="{ active: item.path === pathname }"></div>
          </li>
        </ul>
      </div>
    </div>

    <!-- <el-drawer class="show_wrap" :visible.sync="showFlag">

      <div class="drawer_top_list">
        <ul class="drawer_nume_list">
          <li class="every_nume" v-for="(item, index) in list" :key="index" @click="linkTo(index)"
            :style="item.path === pathname ? 'color: #2c90fe;' : ''">
            <div :class="{ active: item.path === pathname }"></div>
            <div>{{ item.name }}</div>
            <div>
              <i class="el-icon-arrow-right"></i>
            </div>
          </li>
        </ul>
      </div>


      <div class="drawer_bottom" v-if="$store.state.login.token === '' && $store.state.login.token2 === ''">
        <div class="login">
          <div class="l1 " v-if="$store.state.login.token === '' && $store.state.login.token2 === ''">
            <button @click="rCenroll">我要招聘</button>
            <div style="margin: auto 0;cursor:pointer;display: flex;">
              <img src="../assets/img/login_icon.svg" alt="" width="32px" @click="loginImmediately">
              <img src="../assets/img/Line 11.svg" alt="" style="margin: 0 13px;" width="1px">
              <img src="../assets/img/zhuce_icon.svg" alt="" width="32px" @click="registerImmediately">
            </div>
            
          </div>
          <div v-else>
            <div></div>
            <div class="islogin small_none" @mouseleave="show = false">
              <div @mouseenter="show = true">
                <span>您好，{{ name }}</span>
                <i class="el-icon-arrow-down"></i>
              </div>
               求职者
              <template v-if="userType == 1">
                <div class="menu" v-if="show">
                  <ul>
                    <li @click="JobenterCenter(0)">
                      <i class="fa fa-user" aria-hidden="true"></i>
                      用户中心
                    </li>
                    <li @click="JobenterCenter(1)">
                      <i class="fa fa-star" aria-hidden="true"></i>
                      我的收藏
                    </li>
                    <li @click="JobenterCenter(2)">
                      <i class="fa fa-cog" aria-hidden="true"></i>
                      账号设置
                    </li>
                    <li @click="JobenterCenter(3)">
                      <i class="fa fa-sign-out" aria-hidden="true"></i>
                      退出登录
                    </li>
                  </ul>
                </div>
              </template>
                 招聘方
              <template v-else>
                <div class="menu" v-if="show">
                  <ul>
                    <li @click="RCenterCenter(0)">
                      <i class="fa fa-user" aria-hidden="true"></i>
                      用户中心
                    </li>
                    <li @click="RCenterCenter(1)">
                      <i class="fa fa-paper-plane" aria-hidden="true"></i>
                      我的发布
                    </li>
                    <li @click="RCenterCenter(2)">
                      <i class="fa fa-cog" aria-hidden="true"></i>
                      账号设置
                    </li>
                    <li @click="RCenterCenter(3)">
                      <i class="fa fa-sign-out" aria-hidden="true"></i>
                      退出登录
                    </li>
                  </ul>
                </div>
              </template>
            </div>
          </div>

        </div>
      </div>

    </el-drawer>-->

    <!-- 登录弹窗 -->
    <el-dialog
      width="380px"
      class="login_dialog"
      :visible.sync="dialogFormVisible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      :destroy-on-close="true"
      :show-close="true"
      @close="closedia"
    >
      <div class="dialog">
        <div class="form" v-if="enrollif != 2 && enrollif != 3">
          <div class="top_btn">
            <button :class="isbtn ? 'buttona' : ''" @click="isbtn = true">
              <div>
                密码登录
                <div class="titleBottom2"></div>
              </div>
            </button>
            <button :class="isbtn ? '' : 'buttona'" @click="isbtn = false">
              <div>
                短信登录
                <div class="titleBottom2"></div>
              </div>
            </button>
          </div>

          <div :class="isbtn ? 'ftel1' : 'ftel'">
            <!-- 密码登录 -->
            <div class="tel pass">
              <el-form ref="form" :model="form" :rules="rules">
                <el-form-item prop="account" style="margin-bottom: 20px;">
                  <div class="textDiv">手机号码</div>
                  <el-input
                    class="input"
                    auto-complete="new-password"
                    autocomplete="off"
                    clearable
                    v-model="form.account"
                    placeholder="请输入手机号码"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <div class="textDiv">密码</div>
                  <el-input
                    class="input"
                    auto-complete="new-password"
                    autocomplete="off"
                    @keyup.enter.native="loginee"
                    v-model="form.password"
                    placeholder=" 请输入登录密码"
                    :type="openEye == 1 ? '' : 'password'"
                    style="position:relative"
                  ></el-input>
                  <i
                    :class="openEye == 1 ? 'fa fa-eye' : 'fa fa-eye-slash'"
                    style="color: rgb(192, 196, 204);font-size: 14px;position:absolute;top:40px;right:10px;cursor:pointer"
                    @click="openEye = !openEye"
                  ></i>
                </el-form-item>
                <el-form-item>
                  <div class="textDiv2" @click="forgetPass">忘记密码</div>
                  <el-button type="primary" class="loginn" @click="loginee">登 录</el-button>
                </el-form-item>
              </el-form>
            </div>
            <!-- 短信登录 -->
            <div class="tel code">
              <el-form ref="form1" :model="form1" :rules="rules1">
                <el-form-item prop="account">
                  <div class="textDiv">手机号码</div>
                  <el-input class="input" clearable v-model="form1.account" placeholder="请输入手机号码"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <div class="textDiv">验证码</div>
                  <el-input
                    class="input input2"
                    auto-complete="new-password"
                    autocomplete="off"
                    @keyup.enter.native="loginee"
                    v-model="form1.password"
                    placeholder=" 请输入短信验证码"
                  >
                    <template slot="prefix"></template>
                    <template slot="suffix" v-if="code1">
                      <div class="mySpan" @click="getCode">{{ setcode }}</div>
                    </template>
                    <template slot="suffix" v-else>
                      <div class="mySpan">{{ count }}秒可后重发</div>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <el-button
                    style="margin-top:40px"
                    type="primary"
                    class="loginn"
                    @click="loginee"
                  >登 录</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
      <div
        slot="footer"
        style="height:62px;line-height:62px;margin:0 auto;display: flex;justify-content: center;"
      >
        <div style="color: #C0C4CC;">没有账号? &nbsp;</div>
        <div style="color: #2369FF; cursor: pointer" @click="registerImmediately">立即注册</div>
      </div>
    </el-dialog>
    <!-- 注册，忘记密码模块 -->
    <el-dialog
      width="400px"
      :visible.sync="dialogFormVisible2"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      :destroy-on-close="true"
      :show-close="true"
      @close="closedia"
      class="dialogLarge login_dialog"
    >
      <div class="dialog2">
        <div v-if="enrollif == 2" class="reTitle">
          <div>注册学国账号</div>
          <div class="titleBottom"></div>
        </div>
        <div v-if="enrollif == 2">
          <div class="etel">
            <el-form ref="form2" :model="form2" :rules="rules3" class="form_zhuce">
              <el-form-item prop="account">
                <el-input
                  class="input"
                  auto-complete="new-password"
                  autocomplete="off"
                  v-model="form2.account"
                  placeholder="请输入手机号码"
                ></el-input>
              </el-form-item>
              <el-form-item prop="code">
                <el-input class="input input3" v-model="form2.code" placeholder="请输入短信验证码">
                  <template slot="suffix" v-if="code12">
                    <div @click="getCode1" :disabled="clicklimit2" class="mySpan">{{ setcode2 }}</div>
                  </template>
                  <template slot="suffix" v-else>
                    <div class="mySpan">{{ count2 }}秒可后重发</div>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="password" class="myError">
                <el-input
                  class="input"
                  auto-complete="new-password"
                  autocomplete="off"
                  v-model="form2.password"
                  placeholder="请输入登录密码"
                  :type="openEye2 == 1 ? '' : 'password'"
                  style="position:relative"
                ></el-input>
                <i
                  :class="openEye2 == 1 ? 'fa fa-eye' : 'fa fa-eye-slash'"
                  style="color: rgb(192, 196, 204);font-size: 14px;position:absolute;top:15px;right:10px;cursor:pointer"
                  @click="openEye2 = !openEye2"
                ></i>
              </el-form-item>
              <el-form-item>
                <span class="dialogD">*密码要求8-16位，必须包含数字,字母或特殊字符至少两种</span>
              </el-form-item>
              <el-form-item>
                <el-select v-model="form2.role" placeholder="请选择" style="width:100%">
                  <el-option
                    v-for="item in options"
                    :key="item.label"
                    :label="item.role"
                    :value="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item style="align-self:left;">
                <template>
                  <el-radio v-model="form2.role" label="0">学生</el-radio>
                  <el-radio v-model="form2.role" label="1">老师</el-radio>
                </template>
              </el-form-item>-->

              <el-form-item prop="InvitationCode">
                <el-input
                  auto-complete="new-password"
                  autocomplete="off"
                  class="input"
                  v-model="form2.invitedCode"
                  placeholder="请填写邀请码（选填）"
                ></el-input>
              </el-form-item>
              <el-form-item class="btn_wrap">
                <el-button
                  class="btn zhuce_btn"
                  type="primary"
                  @click="enrrol"
                  style="background-color:#2369ff !important;font-size:14px"
                >立即注册</el-button>
              </el-form-item>
              <el-form-item style="text-align:left">
                <el-checkbox class="font_12" v-model="checked" style="margin-right:10px;"></el-checkbox>
                <span style="color:#C0C4CC;">
                  我已阅读并接受
                  <span
                    style="color: #2369FF;cursor: pointer;font-size:12px"
                    @click.stop="touserfile"
                  >《用户协议》</span>和
                  <span
                    style="color: #2369FF;cursor: pointer;font-size:12px"
                    @click.stop="touPolicyInfo"
                  >《隐私政策》</span>
                </span>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div v-else-if="enrollif == 3">
          <div class="etel">
            <div class="dialogTitle">
              <div>密码重置</div>
              <div class="titleBottom"></div>
            </div>
            <el-form ref="form3" :model="form3" :rules="rules2">
              <el-form-item prop="account">
                <el-input
                  class="input"
                  auto-complete="new-password"
                  autocomplete="off"
                  v-model="form3.account"
                  placeholder="请输入手机号码"
                ></el-input>
              </el-form-item>
              <el-form-item prop="code">
                <el-input
                  class="input input3"
                  auto-complete="new-password"
                  autocomplete="off"
                  v-model="form3.code"
                  placeholder="请输入短信验证码"
                >
                  <template slot="suffix" v-if="code13">
                    <div @click="getCode3" :disabled="clicklimit3" class="mySpan">{{ setcode3 }}</div>
                  </template>
                  <template slot="suffix" v-else>
                    <div class="mySpan">{{ count3 }}秒可后重发</div>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="password" class="myError">
                <el-input
                  class="input"
                  auto-complete="new-password"
                  autocomplete="off"
                  v-model="form3.password"
                  placeholder="请输入新登录密码"
                  :type="openEye3 == 1 ? '' : 'password'"
                ></el-input>
                <!-- <i :class="openEye3 == 1 ? 'fa fa-eye' : 'fa fa-eye-slash'"
                  style="color: rgb(192, 196, 204);font-size: 14px;position:absolute;top:15px;left:310px;cursor:pointer"
                @click="openEye3 = !openEye3"></i>-->
              </el-form-item>
              <el-form-item>
                <span class="dialogC">*密码要求8-16位，必须包含数字,字母或特殊字符至少两种</span>
              </el-form-item>
              <el-form-item prop="rePassword">
                <el-input
                  class="input"
                  auto-complete="new-password"
                  autocomplete="off"
                  v-model="form3.rePassword"
                  placeholder="请再次输入新登录密码"
                  :type="openEye4 == 1 ? '' : 'password'"
                ></el-input>
                <!-- <i :class="openEye4 == 1 ? 'fa fa-eye' : 'fa fa-eye-slash'"
                  style="color: rgb(192, 196, 204);font-size: 14px;position:absolute;top:15px;left:310px;cursor:pointer"
                @click="openEye4 = !openEye4"></i>-->
              </el-form-item>
              <el-form-item class="reset">
                <el-button class="btn confirm_btn" type="primary" @click="findPassword" style>立即重置</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div slot="footer" style="height:62px;line-height:62px;">
        <span style="color: #C0C4CC;">已有账号? &nbsp;</span>
        <span
          style="color: #2369FF; cursor: pointer"
          @click="enrollif = 1; dialogFormVisible = true; dialogFormVisible2 = false"
        >马上登录</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
export default {
  name: "TopNav",
  components: {},

  data() {
    let validateAccount = (rule, value, callback) => {
      let RegEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      let RegTel = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/;
      if (value.includes("@")) {
        if (!RegEmail.test(value)) {
          callback(new Error("邮箱格式不正确"));
        } else {
          callback();
        }
      } else {
        if (!RegTel.test(value)) {
          callback(new Error("手机号格式不正确"));
        } else {
          callback();
        }
      }
    };
    let validateAccount2 = async (rule, value, callback) => {
      let RegEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      let RegTel = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/;
      if (value.includes("@")) {
        if (!RegEmail.test(value)) {
          callback(new Error("邮箱格式不正确"));
        } else {
          callback();
        }
      } else {
        if (!RegTel.test(value)) {
          callback(new Error("手机号格式不正确"));
        } else {
          let res = await this.api.isPhoneExist({ phone: value });
          if (res.code == 10201) {
            callback(new Error("该手机号已注册"));
          } else {
            callback();
          }
        }
      }
    };
    let checkpassword = (rule, value, callback) => {
      var pwdRegex = new RegExp("(?=.*[0-9])(?=.*[a-zA-Z]).{8,16}");
      var pwdRegex1 = new RegExp("(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,16}");
      var pwdRegex2 = new RegExp("(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,16}");
      if (
        pwdRegex.test(value) ||
        pwdRegex1.test(value) ||
        pwdRegex2.test(value)
      ) {
        callback();
      } else {
        callback(new Error("密码格式不正确"));
      }
    };
    return {
      showFlag: false,
      dialogFormVisible2: false,
      options: [
        {
          label: "0",
          role: "学生"
        },
        {
          label: "1",
          role: "教师"
        }
      ],

      flag: "",
      lastName: "",
      openEye: 0,
      openEye2: 0,
      openEye3: 0,
      openEye4: 0,
      userName: "",
      setcode: "发送验证码",
      setcode2: "发送验证码",
      setcode3: "发送验证码",
      isbtn: true,
      enrollif: 1,
      clicklimit: false,
      clicklimit2: false,
      clicklimit3: false,
      checked: "",
      str: true,
      activeName: "password",
      count: "",
      count2: "",
      count3: "",
      code1: true,
      code12: true,
      code13: true,
      timer: "",
      form: {
        account: "",
        password: "",
        tel: "",
        code: ""
      },
      form1: {
        account: "",
        password: "",
        tel: "",
        code: ""
      },
      form2: {
        account: "",
        password: "",
        tel: "",
        code: "",
        role: "0",
        invitedCode: ""
      },
      form3: {
        account: "",
        password: "",
        tel: "",
        code: ""
      },
      rules: {
        account: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: validateAccount, trigger: "blur" }
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }]
      },
      rules1: {
        account: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: validateAccount, trigger: "blur" }
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }]
      },
      rules2: {
        account: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: validateAccount, trigger: "blur" }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: checkpassword, trigger: "blur" }
        ]
      },
      rules3: {
        account: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: validateAccount2, trigger: "blur" }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: checkpassword, trigger: "blur" }
        ]
      },
      // rules3: {
      //   account: [
      //     { required: true, message: "请输入手机号", trigger: "blur" },
      //     { validator: validateAccount, trigger: "blur" },
      //   ],
      //   password: [{ required: true, message: "请输入密码", trigger: "blur" },
      //   {validator: checkpassword, trigger: "blur"}
      // ],
      // },
      showclose: false,
      dialogFormVisible: false,

      list: [
        { path: "/", name: "首页" },
        { path: "/RecommendDirectly", name: "公告" },
        // { path: "job", name: "职位" },
        { path: "/Universitypage", name: "单位" },
        // { path: "/Posts", name: "直推" },
        { path: "/TalentInformation", name: "人才资讯" },
        { path: "/CityDetail", name: "地区政策" }
      ],
      user1: ["用户中心", "我的收藏", "账号设置", "退出登录"],
      user2: ["用户中心", "我的发布", "账号设置", "退出登录"],
      current: 0, //删除
      islogin: 0, //是否登录 1登录 0未登录
      show: false, //是否展示下拉菜单
      userType: null //用户类型  1求职着 0招聘方
    };
  },
  // watch: {
  //   $route: {
  //     immediate: true,
  //     handler() {
  //       if (this.$route.query.required) {//需要监听的参数
  //         console.log("~~~",11)
  //         this.getCookiefirst();
  //         console.log(document.cookie)
  //       }
  //     }
  //   }
  // },
  computed: {
    pathname() {
      let pathurl = this.$route.path;
      if (pathurl === "/job") {
        pathurl = "/RecommendDirectly";
      } else if (pathurl === "/Universitydetail") {
        // pathurl = '/University'
        pathurl = "/Universitypage";
      } else if (pathurl === "/TalentInfo") {
        pathurl = "/TalentInformation";
      } else if (pathurl === "/CityDetail_city") {
        pathurl = "/CityDetail";
      } else if (pathurl === "/cityparticulars") {
        pathurl = "/CityDetail";
      }
      console.log(pathurl);
      return pathurl;
    },
    name() {
      // let token = JSON.parse(sessionStorage.getItem("token"));
      // console.log(this.getfirstCookie);
      let myName = sessionStorage.getItem("name");
      let myName2 = sessionStorage.getItem("lastName");
      var strCookie = Cookies.get("token");
     // console.log("strCookie", strCookie);
     // var arr = strCookie.split("=");
      if (strCookie) {
        let token =strCookie;
        let user = jwtDecode(token);
        this.userType = user.userRole;
        this.userName = user.userName;
        if (this.userType == 0) {
          if (myName2) {
            return myName2;
          } else {
            return this.lastName;
          }
        } else if (this.userType == 1) {
          if (myName) {
            return myName;
          } else {
            return user.userName;
          }
        }
        // return user.userName || '求职者';
      }
      //   const token = window.sessionStorage.getItem("token");
      //     let user = jwtDecode(token);
      //     this.userType = user.userRole;
      //     this.userName = user.userName;
      //     console.log(user, ",**********");
      //     return this.userName || "求职者";
    },
    f1() {
      return this.$store.state.islogin;
    }
  },
  watch: {
    f1() {
      if (this.$store.state.islogin) {
        this.dialogFormVisible = this.$store.state.islogin;
        this.$message.warning("请登录后进行操作");
      }
      this.$store.commit("chancelogin", false);
    }
  },
  methods: {
    // 移动端btn
    showNuem() {
      console.log("showNuem");
      this.showFlag = true;
    },
    // 忘记密码
    forgetPass() {
      this.dialogFormVisible = false;
      this.dialogFormVisible2 = true;
      this.enrollif = 3;
      console.log(this.enrollif);
    },
    // 注册
    registerImmediately() {
      this.dialogFormVisible = false;
      this.dialogFormVisible2 = true;
      this.enrollif = 2;
    },
    // 登录btn
    loginImmediately() {
      this.dialogFormVisible = true;
      this.dialogFormVisible2 = false;
      this.enrollif = 1;
    },

    obtainIntegral() {
      this.api.userSignIn().then(res => {
        // console.log(res.data.Integral);
        if (res.code == 100200) {
          this.flag = res.data.Integral;
          // this.$alert("签到成功", {
          //   confirmButtonText: '确定',
          // })
        } else if (res.code == 100503) {
          // this.$alert("请先登录", {
          //   confirmButtonText: '确定',
          // })
        } else {
          this.flag = "1";
          // this.$alert(res.message, {
          //   confirmButtonText: '确定',
          // })
        }
      });
    },
    getUserSignInStatus() {
      this.api.getUserSignInStatus().then(res => {
        console.log(res.data.result);
        if (res.data.result == 1) {
          this.flag = res.data.result;
          // this.$alert("今日已签到", {
          //   confirmButtonText: '确定',
          //   customClass: 'box_wrap'
          // })
        } else {
          // this.$alert("今日未签到", {
          //   confirmButtonText: '确定',
          //   customClass: 'box_wrap'
          // })
        }
      });
    },
    alreadyObtain() {
      // this.$alert('今日已领取', {
      //   confirmButtonText: '确定',
      //   customClass: 'box_wrap'
      // })
    },
    touserfile() {
      window.open("https://xsbg.com/#/lowinfo/UserFile");
    },
    touPolicyInfo() {
      window.open("https://xsbg.com/#/lowinfo/PolicyInfo");
    },
    tohome() {
      window.open("https://xsbg.com/");
    },
    tohome2() {
      this.$router.push("/");
    },
    closedia() {
      this.setcode = "发送验证码";
      this.setcode2 = "发送验证码";
      this.setcode3 = "发送验证码";
      this.isbtn = true;
      // this.enrollif = 1
      this.clicklimit = false;
      this.clicklimit2 = false;
      this.clicklimit3 = false;
      this.checked = "";
      this.str = true;
      this.activeName = "password";
      this.count = "";
      this.count2 = "";
      this.count3 = "";
      this.code1 = true;
      this.code12 = true;
      this.code13 = true;
      this.form = {
        account: "",
        password: "",
        tel: "",
        code: ""
      };
      this.form1 = {
        account: "",
        password: "",
        tel: "",
        code: ""
      };
      this.form2 = {
        account: "",
        password: "",
        tel: "",
        code: "",
        role: "0",
        invitedCode: ""
      };
      this.form3 = {
        account: "",
        password: "",
        tel: "",
        code: ""
      };
    },
    // 求职者导航//删除
    JobenterCenter(value) {
      switch (value) {
        case 0:
          this.nav("JobInformation");
          break;
        case 1:
          this.$router.push({
            name: "JobAnnouncements",
            params: { current: "1" }
          });
          break;
        case 2:
          // this.nav("JobSecuritySettings");
          this.$router.push({
            name: "Security"
          });
          break;
        case 3:
          this.logout();
          break;
      }
    },
    //招聘方导航//删除
    RCenterCenter(value) {
      switch (value) {
        case 0:
          this.nav("Rcindex");
          break;
        case 1:
          this.$router.push({ name: "Rcpublish", params: { current: "1" } });
          break;
        case 2:
          this.nav("SecuritySettings");
          break;
        case 3:
          this.logout();
          break;
      }
    },
    nav(name) {
      this.$router.push({ name: name }).catch(error => {});
    },
    linkTo(value) {
      //删除
      this.current = value;

      switch (value) {
        case 0:
          this.$router.push({ name: "home" }).catch(err => {});

          break;
        case 1:
          this.$router.push({ name: "RecommendDirectly" }).catch(err => {});

          break;
        case 2:
          this.$router
            .push({ name: "Universitypage", params: { current: "1" } })
            .catch(err => {});

          break;
        // case 3:
        //   this.$router.push({ name: "Posts" }).catch((err) => { });
        //   break;
        case 3:
          this.$router.push({ name: "TalentInformation" }).catch(err => {});

          break;
        case 4:
          this.$router
            .push({ name: "CityDetail", params: { current: "1" } })
            .catch(err => {});

          break;
      }
    },
    rCenroll() {
      this.$router.push({ name: "LoginMain" });
    },
    async getCode() {
      let RegTel = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/;
      if (this.form1.account === "") {
        this.$message({
          type: "error",
          message: "请输入手机号"
        });
      } else {
        let c, mes;
        {
          //手机
          if (!RegTel.test(this.form1.account)) {
            this.$message({
              type: "error",
              message: "手机格式不正确"
            });
          }

          if (RegTel.test(this.form1.account)) {
            this.clicklimit = true;
            setTimeout(() => {
              //请求数据。。。
              //请求成功后在返回的状态里面加上下面这句话
              this.clicklimit = false;
            }, 3000);
            let res = await this.serve.getTelCode({
              phone: this.form1.account
            });
            c = res.code;
            this.clicklimit = false;
            mes = res.message;
          }
        }
        if (c === 100200) {
          this.$message({
            type: "success",
            message: "验证码发送成功"
          });
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.code1 = false;
            this.count = TIME_COUNT;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count -= 1;
              } else {
                this.code1 = true;
                this.setcode = "重新发送";
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        } else if (c) {
          this.$message({
            type: "error",
            message: mes
          });
        }
      }
    },
    async getCode1() {
      let RegTel = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/;
      if (this.form2.account === "") {
        this.$message({
          type: "error",
          message: "请输入手机号"
        });
      } else {
        let c, mes;
        {
          //手机
          if (!RegTel.test(this.form2.account)) {
            this.$message({
              type: "error",
              message: "手机格式不正确"
            });
          }

          if (RegTel.test(this.form2.account)) {
            this.clicklimit2 = true;
            setTimeout(() => {
              //请求数据。。。
              //请求成功后在返回的状态里面加上下面这句话
              this.clicklimit2 = false;
            }, 3000);
            let res = await this.serve.getTelCode({
              phone: this.form2.account
            });
            c = res.code;
            this.clicklimit2 = false;
            mes = res.message;
          }
        }

        if (c === 100200) {
          this.$message({
            type: "success",
            message: "验证码发送成功"
          });
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.code12 = false;
            this.count2 = TIME_COUNT;
            this.timer = setInterval(() => {
              if (this.count2 > 0 && this.count2 <= TIME_COUNT) {
                this.count2 -= 1;
              } else {
                this.code12 = true;
                this.setcode2 = "重新发送";
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        } else if (c) {
          this.$message({
            type: "error",
            message: mes
          });
        }
      }
    },
    async getCode3() {
      let RegTel = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/;
      if (this.form3.account === "") {
        this.$message({
          type: "error",
          message: "请输入手机号"
        });
      } else {
        let c, mes;
        {
          //手机
          if (!RegTel.test(this.form3.account)) {
            this.$message({
              type: "error",
              message: "手机格式不正确"
            });
          }

          if (RegTel.test(this.form3.account)) {
            this.clicklimit3 = true;
            setTimeout(() => {
              //请求数据。。。
              //请求成功后在返回的状态里面加上下面这句话
              this.clicklimit3 = false;
            }, 3000);
            let res = await this.serve.getTelCode({
              phone: this.form3.account
            });
            c = res.code;
            this.clicklimit3 = false;
            mes = res.message;
          }
        }
        if (c === 100200) {
          this.$message({
            type: "success",
            message: "验证码发送成功"
          });
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.code13 = false;
            this.count3 = TIME_COUNT;
            this.timer = setInterval(() => {
              if (this.count3 > 0 && this.count3 <= TIME_COUNT) {
                this.count3 -= 1;
              } else {
                this.code13 = true;
                this.setcode3 = "重新发送";
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        } else if (c) {
          this.$message({
            type: "error",
            message: mes
          });
        }
      }
    },
    //注册
    async enrrol() {
      //findPassword
      await this.$refs["form2"].validate(async valid => {
        if (valid) {
          if (this.checked) {
            let res = await this.serve.enrollTel({
              code: this.form2.code,
              phoneNumber: this.form2.account,
              password: this.form2.password,
              role: this.form2.role,
              invitedCode: this.form2.invitedCode
            });
            if (res.code === 100200) {
              this.$message({
                type: "success",
                message: "账号注册成功"
              });
              // let res1=await this.api.login()
              this.getCookiefirst();
              //  this.$store.commit("login/setToken", result.data.Token);
              this.dialogFormVisible = false;
              await this.$router.push("/");
            } else {
              this.$message({
                type: "error",
                message: result.message
              });
            }
          } else {
            this.$message({
              type: "error",
              message: "请阅读《用户协议》《隐私条款》并选择"
            });
          }
        }
      });
    },
    async findPassword() {
      //findPassword
      await this.$refs["form3"].validate(async valid => {
        if (valid) {
          let res = await this.serve.findPassword({
            code: this.form3.code,
            phoneNumber: this.form3.account,
            password: this.form3.password
          });
          if (res.code === 100200) {
            this.$message({
              type: "success",
              message: "密码修改成功"
            });
            // let res1=await this.api.login()
          } else {
            this.$message({
              type: "error",
              message: res.message
            });
          }
        }
      });
    },
    async loginee() {
      if (this.isbtn) {
        //账号密码登录
        await this.$refs["form"].validate(async valid => {
          if (valid) {
            // axios.defaults.withCredentials = true
            let result = await this.serve.login({
              account: this.form.account,
              password: this.form.password
            });
            if (result.code === 100200) {
              this.$message({
                type: "success",
                message: "登录成功"
              });
              // console.log(result);
              // document.cookie = `token=${result.data.token}`
              // const code = jwtDecode(result.data.Token)
              // console.log(code)// 就可以解析成功了
              // sessionStorage.setItem(
              //   "token",
              //   JSON.stringify(result.data.Token)
              // );
              this.getCookiefirst();
              //  this.$store.commit("login/setToken", result.data.Token);
              this.dialogFormVisible = false;
              this.showFlag = false;
              await this.$router.push("/");
            } else {
              this.$message({
                type: "error",
                message: result.message
              });
            }
          }
        });
      } else {
        await this.$refs["form1"].validate(async valid1 => {
          if (valid1) {
            let result = await this.serve.codeLogin({
              phone: this.form1.account,
              code: this.form1.password
            });
            if (result && result.data.code === 100200) {
              this.$message({
                type: "success",
                message: "登录成功"
              });
              this.showFlag = false;

              // let set_cookie = JSON.stringify(result.headers.setCookie)
              // let cookie_arr = set_cookie.split("=")
              // if (cookie_arr[0] == token) {
              // document.cookie = `token=${result.headers.setCookie}`
              // }
              // console.log(result);
              // document.cookie = `token=${result.header.set-cookie}`
              // sessionStorage.setItem(
              //   "token",
              //   JSON.stringify(result.data.Token)
              // );
              this.getCookiefirst();
              this.dialogFormVisible = false;
              // this.$store.commit("login/setToken", result.data.Token);
              this.$router.push("/");
            } else {
              this.$message({
                type: "error",
                message: result.data.message
              });
            }
          }
        });
      }
    },

    async getCookiefirst() {
     // var strCookie = document.cookie;
     var strCookie = Cookies.get("token");
      console.log("strCookie", strCookie);
   //   var arr = strCookie.split("=");
      //
      if (strCookie) {
      //  console.log(arr[1]);
        let token = strCookie;
        let user = jwtDecode(token);

        this.userType = user.userRole;
        if (this.userType == 0) {
          let res = await this.institution.getHomePage();
          if (res.code === 100200) {
            if (res.data.institutionInfo.lastName) {
              this.lastName = res.data.institutionInfo.lastName;
            } else {
              this.lastName = res.data.institutionInfo.name;
            }
          } else {
            //this.$message.error(res.msg)
          }
        }
        this.$store.commit("login/setToken", token);
        //await this.$router.push("/");
      } else {
        this.$store.commit("login/setToken", "");
      }
    },

    delCookie() {
      let date = new Date(); //获取当前时间
      let expiresHours = parseInt(0); //将date设置为n小时以后的时间
      date.setTime(date.getTime() + expiresHours * 0); //格式化为cookie识别的时间
      document.cookie =
        "token =" +
        "" +
        ";path =" +
        "/;" +
        "expires =" +
        date.toGMTString() +
        "; domain =.xsbg.com"; //设置cookie
      document.cookie =
        "token =" +
        "" +
        ";path =" +
        "/;" +
        "expires =" +
        date.toGMTString() +
        "; domain =" +
        (location.host.indexOf(":") > -1
          ? location.host.split(":")[0]
          : location.host); //设置cookie
      console.log(document.cookie);
    },

    // login() {
    //   // this.$router.push({ name: "LoginMain" });
    // },
    enroll() {
      this.$router.push({ name: "RCenroll" });
    },
    //     setCookie (name, value, expiredays) {

    //  var exdate = new Date();

    //  exdate.setDate(exdate.getDate() + expiredays);

    //  document.cookie = name + "=" + value + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString());
    //     },
    logout() {
      this.$confirm("是否退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "confirm_wrap"
      }).then(() => {
        this.$store.commit("login/setToken", "");
        //window.sessionStorage.removeItem("token")
        window.sessionStorage.clear();
        this.delCookie();
        this.$message({
          type: "success",
          message: "退出登录"
        });
        this.$router.push({ path: "/" });
        // this.$router.go(0);
      });
    }
  },
  async created() {
    this.getCookiefirst();
    //获取签到状态
    //this.getUserSignInStatus()
  },
  mounted() {
    this.$bus.$on("loginee", () => {
      this.dialogFormVisible = true;
    });
    this.$bus.$on("showWrap", () => {
      this.showFlag = false;
    });
  }
};
</script>


<style scoped lang="less">
.small_block {
  display: none;
}

.dialog_wrap {
  border-radius: 4px;
  text-align: left;
}

.el-dialog {
  background: red;
}

.flex {
  display: flex;
}

.pass_icon {
  color: rgb(192, 196, 204);
  font-size: 14px;
  position: absolute;
  top: 15px;
  /* left: 310px; */
  right: 10px;
  cursor: pointer;
}

.zhuce_btn {
  background-color: #0e7afe !important;
  width: 360px;
  margin-top: 5px;
}

.confirm_btn {
  background-color: #0e7afe;
  width: 360px;
  margin-top: 5px;
}

.zhuce_btn:hover {
  color: #fff;
}

@color: #999999;

@Size: 14px;

.myImg {
  // display:inline-block;
  cursor: pointer;
  background: url(../assets/img/AboutLogo.png) no-repeat 0px 0px;
  background-size: auto 100%;
  height: 44px;
  width: 115px;
  // border-right: 2px solid #999999;
}

.myLogo {
  height: 22px;
  margin-top: 11px;
  border: 1px solid rgba(218, 218, 218);
  border-radius: 50%;
}

.myImg2 {
  display: inline-block;
  cursor: pointer;
  background: url(../assets/img/AboutLogo.png) no-repeat -120px 0px;
  background-size: auto 100%;
  height: 44px;
  width: 100px;
}

.dialog::v-deep .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 0px;
  width: 100%;
}

.dialog::v-deep .el-form-item__error {
  left: 15px;
}

.dialog {
  position: relative;
  border-radius: 10px;
  // height: 550px;

  // overflow: hidden;
  .form {
    position: relative;

    height: 350px;
    overflow: hidden;

    // padding: 0 20px;
    .top_btn {
      text-align: center;
    }

    button {
      height: 40px;
      width: 150px;
      height: 36px;
      background-color: #fff;
      font-size: 18px;
      line-height: 18px;
      color: #4c4c4c;
      margin-bottom: 15px;
      cursor: pointer;
      border: none;
      // padding: 0 20px;
      cursor: pointer;
    }
  }

  .ftel {
    left: -340px;
    position: absolute;
    width: 840px;
    transition: all 0.3s linear;
  }

  .ftel1 {
    left: 0px;
    position: absolute;
    width: 840px;
    transition: all 0.3s linear;
  }

  .etel {
    padding-top: 30px;
    width: 360px;
    height: 250px;
    margin-left: 50px;

    .input {
      width: 340px;
      height: 50px;
      border: none;
      background-color: transparent;
      outline: none;
      color: rgb(84, 84, 84);
      font-size: 14px;
      border-bottom: 1px solid #ccc;
    }
  }

  .tel {
    float: left;
    // padding: 10px 20px;

    height: 250px;

    // background-color: #ccc;
    .loginn {
      width: 300px;
      background-color: #0e7afe;
      color: #fff;
      font-size: 16px;
      border-radius: 10px;
    }

    .input {
      height: 30px;
      border: none;
      background-color: transparent;
      outline: none;
      color: rgb(84, 84, 84);
      font-size: 14px;
    }
  }

  .btn {
    margin-top: 20px;
    width: 300px;
  }

  .diaback {
    position: absolute;
    height: 40px;
    top: -45px;
    left: 0;
    color: #333333;
    cursor: pointer;

    p {
      float: right;
      height: 40px;
      line-height: 43px;
    }

    i {
      font-size: 24px;
      margin: 8px;
    }
  }
}

.input /deep/.el-input__inner {
  border: 1px solid #ccc;
}

.textDiv {
  text-align: left;
}

/deep/ .el-dialog {
  border-radius: 4px !important;
}

#hzkTP {
  position: sticky;
  top: 0;
  width: 100%;
  max-height: 80px;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  z-index: 999;
  backdrop-filter: blur(10px);
  box-shadow: 0px 5px 10px rgba(15, 41, 131, 0.06);

  .containT {
    background-color: rgb(255, 255, 255);
    width: 1400px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tRight {
      display: flex;
      align-items: center;
    }

    ul {
      display: flex;

      li:nth-child(1),
      li:nth-child(2),
      li:nth-child(3) {
        height: 64px;
        line-height: 64px;
        cursor: pointer;
        color: #000;
        font-size: 16px;
        position: relative;
        margin-right: 40px;

        div {
          position: absolute;
          bottom: 2px;
          height: 4px;
          width: 12px;
          margin-left: 10px;
          transition: all 0.3s ease-in-out;
          border-radius: 0px 2px 0px 0px;
        }

        // &:hover {
        //   color: #2c90fe;
        // }

        // &:hover div {
        //   // background-color: #0079fe;
        //   background-image: linear-gradient(to right, #0400ff, #2c90fe);
        //   width: 100%;
        // }
      }

      li:nth-child(4),
      li:nth-child(5) {
        height: 64px;
        line-height: 64px;
        cursor: pointer;
        color: #000;
        font-size: 16px;
        position: relative;
        margin-right: 40px;

        div {
          position: absolute;
          bottom: 2px;
          height: 4px;
          width: 12px;
          margin-left: 26px;
          transition: all 0.3s ease-in-out;
        }

        // &:hover {
        //   color: #2c90fe;
        // }

        // &:hover div {
        //   // background-color: #0079fe;
        //   background-image: linear-gradient(to right, #0400ff, #2c90fe);
        //   width: 100%;
        // }
      }

      .active {
        background-color: #0079fe;
        // background-image: linear-gradient(to right, #2c90fe, #0400ff);
        display: flex;
        justify-content: center;
        // width: 12px;
        margin: 0 auto;
        text-align: center;
        padding: 0 4px;
      }
    }

    .login {
      margin-left: 10px;
      // color: @color;
      font-size: 15px;
      align-self: center;

      .l1 {
        display: flex;
        justify-content: space-between;
        align-self: center;

        span {
          line-height: 40px;
          margin-left: 24px;
          color: #000000d8;
          font-size: 15px;
          cursor: pointer;
          transition: 0.2s all ease;

          &:hover {
            color: #0079fe;
          }
        }
      }

      .islogin {
        cursor: pointer;
        transition: 0.2s all ease;
        position: relative;

        &:hover {
          color: #0079fe;
        }

        i {
          margin: 0 5px 0 10px;
        }

        .menu {
          z-index: 10;
          background-color: rgb(255, 255, 255);
          padding-top: 22px;
          width: 160px;
          position: absolute;

          ul {
            width: 100%;
            margin: 0;
            box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
            color: #909090;
            display: block;
          }

          li {
            float: none;
            height: 45px;
            width: 100%;
            line-height: 45px;
            color: #909090;

            &:hover {
              background-color: rgb(236, 245, 255);
              color: #70b6ff;
              border: none;
            }
          }
        }
      }
    }
  }
}

.login button {
  width: 92px;
  height: 40px;
  font-weight: bold;
  font-style: normal;
  font-size: 15px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  border-radius: 3px;
  background: #0079fe;
  backdrop-filter: blur(20px);
  box-shadow: 0px 5px 10px 0px #0f29830f;
  margin-left: 24px;

  //&:first-child {
  //  background-color: rgb(0, 121, 254);
  //
  //  &:hover {
  //    background-color: rgba(0, 121, 254, 0.7);
  //  }
  //}
  //
  //&:last-child {
  //  background-color: rgb(255, 255, 255);
  //  color: #3394fe;
  //}
}

.obtain_points {
  cursor: pointer;
  color: #3394fe;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}

.form_zhuce /deep/.el-form-item {
  margin-bottom: 16px;
}

.top_logo {
  width: 275px;
  cursor: pointer;
}

.top_logo img {
  width: 100%;
  height: 100%;
}

//移动端

@media (max-width: 576px) {
  .containT {
    width: 100% !important;
    padding: 0 20px;

    ul {
      display: none !important;
    }

    .obtain_points {
      display: none !important;
    }

    .login {
      width: fit-content !important;

      div {
        button:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .top_logo {
    width: 120px;
    overflow: hidden;
  }

  .top_logo img {
    width: 190%;
    height: 100%;
  }
}

.top_logo_png {
  height: 36px;
  width: 136px;
  cursor: pointer;
  align-self: center;
  margin-right: 56px;
}
</style>
<style>
@media (max-width: 576px) {
  .box_wrap {
    width: 80% !important;
  }

  .dialog_wrap >>> .el-dialog {
    width: 80% !important;
    background-color: aqua;
  }
}
</style>
<style scoped>
@media (max-width: 576px) {
  .dialog_wrap >>> .el-dialog {
    width: 80% !important;
  }

  .top_btn {
    text-align: left;
  }

  .top_btn >>> button {
    margin-right: 0 !important;
  }

  .dialog .form {
    width: 100%;
    margin: 0;
  }

  .dialog .ftel1 {
    width: 100% !important;
  }

  .ftel1 .pass {
    display: block;
  }

  .ftel1 .code {
    display: none;
  }

  .ftel .pass {
    display: none;
  }

  .ftel .code {
    display: block;
  }

  .dialog .tel {
    width: 100%;
    padding: 0;
    left: 0;
  }

  .dialog .ftel {
    width: 100% !important;
    padding: 0;
    left: 0;
  }

  .tel >>> .el-form {
    margin: 0 auto;
  }

  .dialog .tel .input {
    width: 100%;
  }

  .dialog .form button {
    height: 36px;

    background-color: #fff;
    font-size: 18px;
    line-height: 18px;
    color: #4c4c4c;
    margin-bottom: 15px;
    cursor: pointer;
    border: none;
    padding: 0 20px;
  }

  .dialog .tel .loginn {
    width: 100%;
    margin-left: 0;
  }

  .dialog {
    height: auto;
  }

  .dialog .etel {
    width: 100%;
    margin-left: 0;
    height: auto;
  }

  .dialog .etel .input {
    width: 100%;
  }

  .dialog .btn {
    width: 100%;
  }

  .font_12 >>> .el-checkbox__label {
    font-size: 12px;
  }

  .menu ul {
    display: block !important;
  }

  #hzkTP .containT .login .islogin .menu {
    right: 0;
  }
}
</style>
<style>
@media (max-width: 576px) {
  .confirm_wrap {
    width: 80% !important;
  }

  .dialog .el-input__inner {
    width: 100% !important;
  }
}
</style>
<style lang="less" scoped>
/deep/ .el-dialog {
  border-radius: 4px !important;
}

/deep/ .el-input__suffix {
  right: 0;
}

/deep/ .el-dialog__body {
  padding: 10px 40px;
}

.form_zhuce /deep/ .el-form-item {
  margin-bottom: 16px;
}

/deep/ .el-dialog__footer {
  text-align: center;
  padding: 0;
  margin-top: 20px;
  background-color: #fafafa;
  border-radius: 0 0 4px 4px;
}

.mySpan {
  width: 100px;
  height: 40px;
  margin-left: 8px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  color: #666666;
  cursor: pointer;

  span {
    height: 24px;
    width: 70px;
    font-size: 14px;
    line-height: 24px;
  }
}

.dialog::v-deep .el-input__inner {
  background: #ffffff;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  width: 298px;
  height: 40px;
}

.dialogLarge {
  /deep/ .el-dialog__body {
    padding: 10px 34px;
  }

  .etel {
    /deep/ .el-form-item__content {
      .dialogC {
        font-weight: 400;
        font-size: 12px;
        line-height: 13px;
        display: flex;
        align-items: center;
        color: #999999;
        margin-top: -18px;
      }

      .dialogD {
        font-weight: 400;
        font-size: 12px;
        line-height: 13px;
        display: flex;
        align-items: center;
        color: #999999;
        margin-top: -10px;
      }
    }
  }

  .dialogTitle {
    height: 36px;
    width: 72px;
    font-size: 18px;
    line-height: 18px;
    color: #4c4c4c;
    margin-bottom: 22px;

    .titleBottom2 {
      width: 72px;
      height: 3px;
      background-color: #428bf9;
      margin: 15px auto 0;
      border-radius: 10px;
    }
  }

  .reTitle {
    height: 36px;
    font-size: 18px;
    line-height: 18px;
    color: #4c4c4c;
    margin-bottom: 22px;
    text-align: center;

    .titleBottom {
      width: 56px;
      height: 3px;
      background-color: #428bf9;
      margin: 15px auto 0;
      border-radius: 10px;
    }
  }

  /deep/ .el-button {
    width: 100%;
  }

  .input3 {
    text-align: left;

    /deep/ .el-input__inner {
      width: 224px;
    }
  }

  .myError {
    /deep/ .el-form-item__error {
      margin-top: 20px;
    }
  }
}

.dialog {
  position: relative;
  border-radius: 4px;
  // height: 550px;

  // overflow: hidden;
  .form {
    // margin: 30px 40px;
    position: relative;
    // width: 420px;
    // height: 450px;
    overflow: hidden;
    text-align: left;

    // padding: 0 20px;
    button {
      height: 36px;
      width: 72px;
      cursor: pointer;
      border-bottom: 4px solid transparent;
      background-color: #fff;
      margin-right: 32px;

      div {
        font-size: 18px;
        line-height: 18px;
        color: #4c4c4c;
        margin-bottom: 15px;
      }
    }

    .buttona {
      color: #ffffff;

      .titleBottom2 {
        width: 72px;
        height: 3px;
        background-color: #428bf9;
        margin: 15px auto 0;
        border-radius: 10px;
      }
    }
  }

  .ftel {
    top: 76px;
    left: -420px;
    position: absolute;
    width: 840px;
    transition: all 0.3s linear;
  }

  .ftel1 {
    top: 76px;
    left: 0px;
    position: absolute;
    width: 840px;
    transition: all 0.3s linear;
  }

  .tel {
    float: left;
    // padding: 10px 40px;
    width: 420px;
    height: 250px;

    // background-color: #ccc;
    .loginn {
      height: 42px;
      width: 300px;
      background-color: #2369ff;
      color: #fff;
      font-size: 16px;
      border-radius: 4px;
      // margin-top: 40px;
    }

    .input {
      width: 298px;
      height: 40px;
      background: #ffffff;
      border-radius: 4px;
      background-color: transparent;
      outline: none;
      color: #c0c4cc;
      font-size: 14px;
    }

    .input2 {
      /deep/ .el-input__inner {
        width: 192px;
      }
    }
  }

  .btn {
    margin-top: 20px;
    width: 300px;
  }

  .diaback {
    position: absolute;
    height: 40px;
    top: -45px;
    left: 0;
    color: #333333;
    cursor: pointer;

    p {
      float: right;
      height: 40px;
      line-height: 43px;
    }

    i {
      font-size: 17px;
      margin: 10px 0;
      color: #333;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.textDiv {
  height: 24px;
  line-height: 24px;
  margin-bottom: 10px;
}

.textDiv2 {
  height: 24px;
  line-height: 24px;
  margin-top: -4px;
  margin-bottom: 20px;
  margin-left: 244px;
  color: #2369ff;
  cursor: pointer;
}

.border {
  width: 20px;
  height: 3px;
  border-radius: 5px;
  background-color: #2369ff;
  margin: 2px 0;
}

@media (max-width: 576px) {
  #hzkTP {
    z-index: auto;
    max-height: 200px !important;
    width: 100% !important;
  }

  .small_block {
    display: block;
    width: 100%;
  }

  .show_wrap {
    width: 100%;
    z-index: 9999;
  }

  .show_wrap /deep/.el-drawer {
    width: 100% !important;
    background: linear-gradient(0deg, #5587f2, #fff);
  }

  .show_wrap /deep/.el-dialog__close {
    font-weight: 700;
    color: #2369ff;
  }

  .small_none {
    display: none !important;
  }

  .containT {
    z-index: 9;
  }

  .drawer_top_list {
    .drawer_nume_list {
      width: 100%;
      padding: 0 40px;
    }

    .every_nume {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px 0;
    }
  }

  .drawer_bottom {
    width: 100%;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    height: 80px;

    .l1 {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }

  .login_dialog /deep/.el-dialog {
    width: 80% !important;

    button {
      width: auto;
    }

    .tel,
    .input {
      width: 100%;
    }

    .form {
      text-align: center;
    }

    .textDiv2 {
      width: 100%;
      text-align: right;
      margin-left: 0;
    }

    .mySpan {
      width: auto;
    }

    .ftel {
      left: 0;
    }

    .loginn {
      width: 100%;
    }
  }

  .top_wrap {
    background-color: #2369ff;
    width: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-top: 30px;
    padding-bottom: 15px;
    padding-right: 24px;
  }

  .top_wrap .islogin {
    position: relative;
  }

  .top_wrap .tRight {
    text-align: center;
  }

  .top_wrap .menu {
    position: absolute;
    color: #909090;
    background-color: #fff;
    width: 140px;
    padding: 0 20px;
    right: 0;
    top: 43px;
    z-index: 9999 !important;
  }

  .top_wrap .menu li {
    margin: 10px 0;
  }

  .top_wrap .menu li :hover {
    background-color: rgb(236, 245, 255);
    color: #70b6ff;
    border: none;
  }

  .top_logo_png {
    margin-right: 10px;
  }

  .l1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
  }

  .l1 span {
    display: block;
    padding: 0 5px;
  }

  .login button {
    background-color: #2369ff;
    margin-left: 0;
    width: auto;
  }

  .bottom_wrap {
    height: 55px;
    background-color: #fff;
  }

  .bottom_wrap /deep/ ul {
    display: flex;
    align-items: center;
    height: 55px;
    justify-content: space-around;
  }

  .bottom_wrap /deep/ ul li {
    line-height: 50px;
  }

  .bottom_wrap /deep/ ul li .active {
    height: 4px;
    width: 12px;
    background-color: #0079fe;
    margin: 0 auto;
  }
}
</style>