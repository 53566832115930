import server from "@/unit/server";

const institution = {
  //热招职位
  getHotPosition: function () {
    return server.get("/home/getHotRecruitment1");
  },
  //推荐优质单位
  getRecommendInstitution: function (params) {
    return server.get(`/home/getSepcialUnit?type=${params}`);
  },
  //引才专项计划
  getRenCaiZhuanXiang: function (params) {
    return server.get(
      `/home/getRenCaiZhuanXiang?type=${params.type}&more=${params.more}`
    );
  },
  download: function (params) {
    return server.get("/institution/getCertificate", params);
  },
  register: function (data) {
    return server.post("institution/register", data);
  },
  //手机登录
  loginByPhone: function (data) {
    return server.post("/institution/loginByPhone", data);
  },
  //手机号注册
  registerByPhone: function (data) {
    return server.post("/institution/registerByPhone", data);
  },
  //注册信息回显
  feedback: function (data) {
    return server.get("/institution/getDetail", data);
  },
  //基本信息下一步
  secondNext: function (data) {
    return server.post("/institution/detailOne", data);
  },
  //资质认证下一步
  submitForm: function (data) {
    return server.post("/institution/detailTwo", data);
  },
  //短信登录
  login: function (data) {
    return server.post("institution/loginByPassword", data);
  },
  //获取手机验证码
  getTelCode: function (params) {
    return server.get("institution/getPhoneCode", params);
  },
  //手机验证码确认
  getConfirm: function (data) {
    return server.post("institution/isPhone", data);
  },
  //更换手机时，确认原手机
  phoneConfirm: function (data) {
    return server.post("institutionInfo/judgePhone", data);
  },
  //更换手机
  changePhone: function (data) {
    return server.post("institutionInfo/changePhone", data);
  },
  //修改登陆密码
  changePass: function (params) {
    return server.get("institutionInfo/reset", params);
  },
  //上传头像
  getUpload: function (data) {
    return server.post("institution/fileUpload", data);
  },
  //获取学科数据
  getSubject: function (params) {
    return server.get("subject/getAllSubject", params);
  },
  //获取发布的公告列表
  getPublish: function (params) {
    return server.get("institutionAnnouncement/getAll", params);
  },
  //获取招聘方个人中心首页信息
  getHomePage: function (params) {
    return server.get("institutionInfo/getInfo", params);
  },
  //修改信息
  changeInfo: function (data) {
    return server.post("institutionInfo/updateInfo", data);
  },
  //获得消息列表
  getMessage: function (data) {
    return server.get("institutionInfo/getMessage", data);
  },
  //删除消息
  deleteMessage: function (data) {
    return server.get("institutionInfo/deleteMessage", data);
  },
  //获取审核状态
  getAuditInfo: function (params) {
    return server.get("institution/getAuditInfo", params);
  },
  //获取具体公告
  getCurrentNotice: function (params) {
    return server.get("institutionAnnouncement/getById", params);
  },
  usersafe: function (params) {
    return server.get("institutionInfo/getUserInfo", params);
  },
  usersafe2: function (params) {
    return server.get("talentInformation/getVoById", params);
  },
  //更变打开与否
  changeOpen: function (params) {
    return server.get("institutionAnnouncement/open", params);
  },
  deleteItem: function (params) {
    return server.get("institutionAnnouncement/deleteAnnouncement", params);
  },
  //获取邮箱验证码
  getEmailCode: function (params) {
    return server.get("institution/getEmailCode", params);
  },
  //邮箱验证码确认
  getEmailConfirm: function (data) {
    return server.post("/institution/codeJudge", data);
  },
  auditEmailCode: function (data) {
    return server.get("/institution/auditEmailCode", data);
  },
  //更改邮箱
  changeEmail: function (data) {
    return server.post("institutionInfo/changeEmail", data);
  },
  //上传证明材料
  sendEvidence: function (data) {
    return server.get("institution/saveEvidence", data);
  },
  //手机注册
  enrollTel: function (data) {
    return server.post("/institution/register", data);
  },

  enrollEmail: function (data) {
    return server.post("/institution/register/email", data);
  },
  codeLogin: function (data) {
    return server.post("/institution/loginByPhone", data);
  },
  uploadPost: function (data) {
    return server.post("institutionAnnouncement/releaseAnnouncement", data);
  },
  // valRegistPhone
  valRegistPhone: function (params) {
    return server.get(`/institution/valRegistPhone?phone=${params}`);
  },
};

export default institution;
