import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "../src/assets/css/common.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import api from "@/unit/api";
import axios from "axios";
import serve from "@/unit/Interface";
import VueClipboard from "vue-clipboard2";
import institution from "@/unit/institution";
import "font-awesome/css/font-awesome.min.css";

Vue.config.productionTip = false;
Vue.use(VueClipboard);
Vue.use(ElementUI);


// import TDesign from 'tdesign-vue';
// 引入组件库的少量全局样式变量
// import 'tdesign-vue/es/style/index.css';
// Vue.use(TDesign);

import Bus from "./lib/bus";

Vue.prototype.$bus = Bus;

Vue.prototype.api = api;
Vue.prototype.serve = serve;
Vue.prototype.institution = institution;
window.document.title = "学国人才";

Vue.filter("showAge", function (value) {
  // 指定日期
  let birthdays = new Date(value.replace(/-/g, "/"));
  // 当前系统日期
  let d = new Date();
  let age =
    d.getFullYear() -
    birthdays.getFullYear() -
    (d.getMonth() < birthdays.getMonth() ||
    (d.getMonth() == birthdays.getMonth() && d.getDate() < birthdays.getDate())
      ? 1
      : 0);
  return age;
});

let Myvue = new Vue({
  router,
  store,
  axios,
  render: (h) => h(App),
}).$mount("#app");
export default Myvue;
