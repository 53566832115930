import axios from "axios";
import router from "@/router";
import store from "@/store";
import message from "element-ui/packages/message";
import Myvue from "@/main.js";

//创建axios实例
// axios.defaults.withCredentials=true;
const server = axios.create({
  baseURL: "/xueguo",
  timeout: 100000,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
//request拦截器
server.interceptors.request.use(
  (config) => {
    // console.log("@@@@")
    const token1 = window.sessionStorage.getItem("token")?.split('"')[1];
    if (token1) {
      config.headers.token = token1;
    }
    let strCookie = document.cookie || [];
    if (strCookie.length > 0) {
      let arr = strCookie.split("=");
      if (arr[0] === "token" && arr[1] !== "") {
        let token = arr[1];
        config.headers.token = token;
        return config;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);
//respone拦截器
server.interceptors.response.use(
  (res) => {
    if (res.status === 200) {
      if (res.status === 100503) {
        // sessionStorage.removeItem('token')
        document.cookie =
          "token =" +
          "" +
          ";path =" +
          "/;" +
          "expires=" +
          date.toGMTString() +
          "; domain=.xsbg.com"; //设置cookie
        store.commit("login/setToken", "");
        //this.$router.push('/')
        message({
          type: "error",
          message: "登录信息丢失,请重新登录",
        });
        return router.replace({ name: "home" });
      } else if (res.data.code === 100501) {
        let date = new Date(); //获取当前时间
        let expiresHours = parseInt(0); //将date设置为n小时以后的时间
        date.setTime(date.getTime() + expiresHours * 0); //格式化为cookie识别的时间
        document.cookie =
          "token =" +
          "" +
          ";path =" +
          "/;" +
          "expires =" +
          date.toGMTString() +
          "; domain =.xsbg.com"; //设置cookie
        document.cookie =
          "token =" +
          "" +
          ";path =" +
          "/;" +
          "expires =" +
          date.toGMTString() +
          "; domain =" +
          (location.host.indexOf(":") > -1
            ? location.host.split(":")[0]
            : location.host);

        // this.$store.commit("login/setToken", "");

        // this.$store.commit("SET_USER", null);
        //this.$router.push('/')
        Myvue.$bus.$emit("errorDialog");
        // message({
        //   type: "error",
        //   message: "登录过期,请重新登录",
        // });
        // router.replace({ path: "/" }); //跳转首页
      } else {
        return Promise.resolve(res);
      }
    } else {
      return Promise.reject(res);
    }
  },
  (error) => {
    const { response } = error;
    if (response) {
      console.log();
    } else {
      console.log("网络请求中断", error);
    }
  }
);

export default {
  get(url, params) {
    return new Promise((resolve, reject) => {
      server
        .get(url, { params: params })
        .then((res) => {
          resolve(res.data);
          return res.data;
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  post(url, data, flag = false) {
    return new Promise((resolve, reject) => {
      server
        .post(url, data)
        .then((res) => {
          if (!flag) {
            resolve(res.data);
          } else {
            resolve(res);
          }
          return res.data;
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
