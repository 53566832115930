import server from "@/unit/server";

const serve = {
  //登录
  login: function (data) {
    console.log("loginface");
    return server.post("/user/login", data);
  },
  //手机验证码
  getTelCode: function (params) {
    return server.get("/user/getPhoneCode", params);
  },
  //邮箱验证码
  getEmailCode: function (params) {
    return server.get("/user/getEmailCode", params);
  },
  //手机注册
  enrollTel: function (data) {
    return server.post("/user/register", data);
  },
  //找回密码
  findPassword: function (data) {
    return server.post("/user/findPassword", data);
  },
  enrollEmail: function (data) {
    return server.post("/user/register/email", data);
  },
  codeLogin: function (data) {
    console.log("codeLogin", data);
    return server.post("/user/loginByPhone", data, true);
  },
};

export default serve;
