import server from "@/unit/server";

const api = {
  //招聘者注册
  interviewerRegister: (params) => {
    return server.post("/institution/register", params);
  },
  //登录
  login: function (data) {
    return server.post("user/login", data);
  },
  //验证手机是否存在
  isPhoneExist: function (data) {
    return server.get(`user/isPhoneExist?phone=${data.phone}`);
  },
  //手机验证码
  getTelCode: function (params) {
    return server.get("/user/getPhoneCode", params);
  },
  //邮箱验证码
  getEmailCode: function (params) {
    return server.get("/user/company/getEmailCode", params);
  },
  //短信登陆
  codeLogin: function (data) {
    console.log("data:", data);
    return server.post("/user/loginByPhone", data);
  },
  //上传证明材料
  sendEvidence: function (data) {
    return server.post("", data);
  },
  //手机注册
  enrollTel: function (data) {
    return server.post("  /user/company/register/phone", data);
  },
  enrollEmail: function (data) {
    return server.post("   /user/company/register/email", data);
  },

  // 人才资讯页面
  personRank: function () {
    return server.get("talentInfo/getHotInfo");
  },

  //人才分页内容
  personAll: function (data) {
    return server.post("talentInfo/getAll", data);
  },

  //人才资讯详情
  personinfo: function (id) {
    return server.get("talentInfo/", id);
  },

  //推送资讯信息
  pushTalentInfo: function (data) {
    return server.get("talentInfo/pushTalentInfo", data);
  },

  //获取随机详情
  getRandomInfo: function (id) {
    return server.get("talentInfo/getRandomInfo", id);
  },

  //首页
  getSlide: function () {
    return server.get("home/getSlide");
  },
  //首页大图
  getBanner: function () {
    return server.get("/home/getBanner");
  },
  //推荐职位
  job: function (data) {
    return server.get("job/getpush", data);
  },

  // 双一流高校
  job6: function (data) {
    return server.get(`home/getnewCollege?type=${data.type}`);
  },
  // 医院
  hospital: function (data) {
    return server.get("home/getHospital", data);
  },
  // 热招
  hot6: function (data) {
    return server.get("home/getHotRecruitment", data);
  },

  optimization: function (data) {
    return server.get("/home/showRecruitment", data);
  },

  getHotCity: function () {
    return server.get("home/getHotCity");
  },

  getTalentInfo: function (data) {
    return server.get("/home/getTalentInfo", data);
  },
  // 轮播图
  getSlide: function () {
    return server.get("/home/getSlide");
  },

  //最新职位
  newjob: function (data) {
    return server.get("job/getlast", data);
  },

  //首页跳转职位
  skipjob: function (id) {
    return server.get("job/detail", id);
  },

  // pushTalentInfo:function() {
  //     return server.post('talentInfo/pushTalentInfo',data)
  // },

  hotinfo: function () {
    return server.get("talentInfo/getLatestInfo");
  },

  hottitle: function () {
    return server.get("talentInfo/getHotWord");
  },
  rotation: function () {
    return server.get("talentInfo/getSlideShow");
  },
  //城市
  city: function () {
    return server.get("city");
  },
  //22
  citybig: function () {
    return server.get("citybig");
  },
  //专业
  subject: function () {
    return server.get("subject");
  },
  //职位
  position: function (data) {
    return server.post("job/getAll", data);
  },
  // 精选推荐
  getFeatured: function (data) {
    return server.post("job/getFeatured", data);
  },
  //相关职位信息
  connectpush: function (data) {
    return server.get("job/connectpush", data);
  },
  getAllPost: function (data) {
    return server.get("subject/getAllPost", data);
  },
  //高校
  university: function (data) {
    return server.post("university/getAll", data);
  },
  //精选推荐
  getuniversityFeatured: function (data) {
    return server.post("university/getFeatured", data);
  },
  universityall: function () {
    return server.get("university");
  },
  //id找高校
  connect: function (data) {
    return server.get("university/getDetail", data);
  },
  //名字找高校
  connectname: function (data) {
    return server.get("university/getByName", data);
  },
  //高校的职位
  universityjob: function (data) {
    return server.get("job/getByName", data);
  },
  //相似高校
  similiar: function (data) {
    return server.get("university/getSimilar", data);
  },
  userinfo: function () {
    return server.get("talentInformation/getInfoById");
  },
  //更新用户信息
  updateinfo: function (data) {
    return server.post("talentInformation/updateInfo", data);
  },
  //密码更改
  usersafe: function () {
    return server.get("talentInformation/getVoById");
  },
  updatepassword: function (data) {
    return server.post("talentInformation/updatePassword", data);
  },
  codeEmailJudge: function (data) {
    return server.post("talentInformation/codeJudge", data);
  },
  updateEmail: function (data) {
    return server.post("talentInformation/updateEmail", data);
  },
  updatePhone: function (data) {
    return server.post("talentInformation/updatePhone", data);
  },
  //地区政策
  getRecommendedInformation: function (city) {
    return server.get(`/regional/getInformationByCity?city=${city}`);
  },
  previncegetall: function (data) {
    return server.get("regional/getInfoByIp", data);
  },
  regionalgetById: function (data) {
    return server.get("regional/getById", data);
  },
  regionalgetrandom: function (data) {
    return server.get("regional/getrandom", data);
  },
  citydetail: function () {
    return server.get("city/detail");
  },
  cityList: function () {
    return server.get("city/list");
  },
  regionalgetByName: function (data) {
    return server.get("regional/getInfoByName", data);
  },

  cityName: function (data) {
    return server.get("city/name", data);
  },
  getJobCollection: function (data) {
    return server.get("collectRecruitment/getCollection", data);
  },
  JobCollection: function (data) {
    return server.get("collectRecruitment/collect", data);
  },
  universityCollection: function (data) {
    return server.get("collectUniversity/collect", data);
  },
  getUniversityCollection: function (data) {
    return server.get("collectUniversity/getCollection", data);
  },
  regionalCollection: function (data) {
    return server.get("collectRegional/collect", data);
  },
  getRegionalCollection: function (data) {
    return server.get("collectRegional/getCollection", data);
  },
  infoCollection: function (data) {
    return server.get("collectTalent/collect", data);
  },
  getInfoCollection: function (data) {
    return server.get("collectTalent/getCollection", data);
  },
  userSignIn: function () {
    return server.get("user/userSignIn");
  },
  getUserSignInStatus() {
    return server.get("/user/getUserSignInStatus");
  },
  getRenCaiZhuanXiang(data) {
    return server.get(`/home/getRenCaiZhuanXiang?type=${data}&more=true`);
  },
  // 公告学科二级菜单

  getSecondSubject(params) {
    return server.get(`/subject/getSecondSubject?subject=${params}`);
  },
 
};
export default api;
