<template>
  <div class="contain">

    <TopNav></TopNav>

    <div class="center">
      <keep-alive>
        <!-- <router-view></router-view> -->
        <router-view :key="$route.fullPath"></router-view>
      </keep-alive>
    </div>
    <footer>
      <FooterNav></FooterNav>
    </footer>
    <div class="rew">
      <img src="../assets/img/二维码.jpg" alt="" width="120px" />
      <span><br>扫码关注学国公众号<br>关注人才和科研</span>
    </div>
    <div class="iasd" v-if="btnFlag" @click="backTop"><i class="fa fa-angle-up" aria-hidden="true"></i></div>
    <DialogWrap :errorFlag.sync="errorFlag"></DialogWrap>

  </div>
</template>

<script>
import TopNav from "../components/TopNav";
import FooterNav from "../components/FooterNav";
import DialogWrap from "../components/DialogWrap/index"

export default {
  name: "Index",
  components: {
    TopNav, FooterNav, DialogWrap
  },
  data() {
    return {
      errorFlag: false,
      btnFlag: false,
      status: 0,
    }
  },

  methods: {
    backTop() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 1);
    },

    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      const that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 60) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },
  },

  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
    this.$bus.$on('errorDialog', () => {

      this.errorFlag = true
    })

  }
}
</script>

<style scoped lang="less">
.rew {
  position: fixed;
  padding: 10px 0;
  bottom: 150px;
  right: 0px;
  width: 145px;
  height: 200px;
  z-index: 999;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;

  img {
    margin-left: 50%;
    transform: translateX(-50%);
  }
}

.iasd {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 50px;
  right: 20px;
  z-index: 999;
  color: #007bff;
  font-size: 36px;
  line-height: 45px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px rgb(202, 202, 202);
  cursor: pointer;
}

.iasd:hover {
  box-shadow: 0 0 5px #0079fe;
}

.contain {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .center {
    flex: 1 1 auto;
    background-color: #f2f2f2;

  }

  footer {}
}
@media (min-width: 576px) {
  @media (max-width: 1200px) {
    .contain {
      width: 1200px!important;
    }
  }
}

// 移动端

@media(max-width: 576px) {
  .rew {
    display: none !important;
  }
}
</style>