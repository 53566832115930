<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive">
      </router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" ></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {
  }
}
</script>


<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // height: 100vh;
  
}
.el-popup-parent--hidden{
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  padding-right: 0 !important;
}
a{
  color: #000;
}
i{
  font-style: normal;
}
body{
  overflow-y: scroll;
}
body::-webkit-scrollbar  {
  width: 10px;
}

body::-webkit-scrollbar-thumb {
  background: #ccc; // 滑块颜色
  border-radius: 5px; // 滑块圆角
}

body::-webkit-scrollbar-thumb:hover {
  background: rgb(130, 130, 130); // 鼠标移入滑块变红
}

</style>
