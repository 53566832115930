import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/Index";
import Home from "../views/Home";
import Myvue from "../main.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: index,
    meta: {
      title: "首页",
    },
    children: [
      {
        path: "",
        name: "home",
        component: Home,
      },
      {
        path: "/Posts", //职位
        name: "Posts",
        component: () => import("../views/Posts/index"),
        //  component: () => import('../views/Login/Enroll')
      },
      {
        path: "/postdetail", //职位详情
        name: "postdetail",
        component: () => import("../views/PostDetail/index"),
      },
      {
        path: "/RecommendDirectly",
        name: "RecommendDirectly",
        component: () => import("../views/RecommendDirectly"),
      },
      {
        path: "/job",
        name: "job",
        component: () => import("../views/Posts/job"),
      },
      {
        path: "/University", //old 高校
        name: "University",
        component: () => import("../views/University/index"),
      },
      {
        path: "/Universitypage", //new 高校
        name: "Universitypage",
        component: () => import("../views/UniversityPage/index"),
      },
      {
        path: "/Universitydetail", //new 高校详情
        name: "Universitydetail",
        component: () => import("../views/UniversityDetail/index"),
      },
      {
        path: "/UniversityDetail", //高校详情
        name: "UniversityDetail",
        component: () => import("../views/University/UniversityDetail"),
      },
      {
        path: "/TalentInformation", //人才资讯
        name: "TalentInformation",
        component: () => import("../views/TalentInformation/index"),
      },
      {
        path: "/LeadSpecial", ///引才专项计划
        name: "LeadSpecial",
        component: () => import("../views/LeadSpecial/index"),
      },
      //地区政策 省份
      {
        path: "/CityDetail",
        name: "CityDetail",
        component: () => import("../views/RegionalPolicies/CityDetail1"),
      },
      //地区政策 地级市
      {
        path: "/CityDetail_city",
        name: "CityDetail_city",
        component: () => import("../views/RegionalPolicies/CityDetail"),
      },

      {
        path: "/cityparticulars", //地区
        name: "cityparticulars",
        component: () => import("../views/RegionalPolicies/cityparticulars"),
      },
      {
        path: "/TalentInfo", //查看人才
        name: "TalentInfo",
        component: () => import("../views/TalentInformation/TalentInfo"),
      },
      {
        path: "/SearchTalent", //人才资讯搜索
        name: "SearchTalent",
        component: () => import("../views/TalentInformation/SearchTalent"),
      },

      {
        path: "/JobseekersCenter", //个人中心
        name: "JobseekersCenter",
        component: () => import("../views/UserCenter/JobseekersCenter/index"),
        children: [
          {
            path: "JobInformation", //基本信息
            name: "JobInformation",
            meta: {
              title: "基本信息",
              index: 0,
            },
            component: () =>
              import("../views/UserCenter/JobseekersCenter/JobInformation"),
          },
          {
            path: "ReviseJobInformation", //编辑基本信息
            name: "ReviseJobInformation",
            meta: {
              title: "基本信息",
              index: 0,
            },
            component: () =>
              import(
                "../views/UserCenter/JobseekersCenter/ReviseJobInformation"
              ),
          },
          {
            path: "Collection",
            name: "Collection",

            meta: {
              title: "我的收藏",
              index: 1,
            },
            component: () =>
              import("../views/UserCenter/JobseekersCenter/Collection"),
            children: [
              {
                path: "JobAnnouncements/:current", //收藏职位公告
                name: "JobAnnouncements",
                meta: {
                  select: 0,
                  index: 1,
                },
                component: () =>
                  import(
                    "../views/UserCenter/JobseekersCenter/Collection/JobAnnouncements"
                  ),
              },
              {
                path: "Unit/:current", //收藏单位
                name: "Unit",
                meta: {
                  select: 1,
                  index: 1,
                },
                component: () =>
                  import(
                    "../views/UserCenter/JobseekersCenter/Collection/Unit"
                  ),
              },
              {
                path: "Information/:current", //收藏资讯
                name: "Information",
                meta: {
                  select: 2,
                  index: 1,
                },
                component: () =>
                  import(
                    "../views/UserCenter/JobseekersCenter/Collection/Information"
                  ),
              },
              {
                path: "RegionalPolicies/:current", //收藏地区政策
                name: "RegionalPolicies",
                meta: {
                  select: 3,
                  index: 1,
                },
                component: () =>
                  import(
                    "../views/UserCenter/JobseekersCenter/Collection/RegionalPolicies"
                  ),
              },
            ],
          },
          {
            path: "JobMessage/:current",
            name: "JobMessage",
            meta: {
              title: "消息中心",
              index: 2,
            },
            component: () =>
              import("../views/UserCenter/JobseekersCenter/JobMessage"),
          },
          {
            path: "Security",
            name: "Security",
            meta: {
              title: "安全设置",
              index: 3,
            },
            component: () =>
              import("../views/UserCenter/JobseekersCenter/Security"),
          },
          {
            path: "AccountBinding",
            name: "JobAccountBinding",
            meta: {
              title: "账户绑定",
              index: 4,
            },
            component: () =>
              import("../views/UserCenter/RecruiterCenter/AccountBinding"),
          },
        ],
      },
      {
        path: "/PostJob", //发布招聘
        name: "PostJob",
        component: () => import("../views/UserCenter/PostJob"),
        meta: {
          keepAlive: true,
          isBack: false,
        },
      },
      {
        path: "/EditJob",
        name: "EditJob",
        meta: {
          title: "编辑职位",
          keepAlive: true,
          isBack: false,
        },
        component: () => import("../views/UserCenter/EditJob"),
      },
      {
        path: "/JobPreview", //公告预览,
        name: "JobPreview",
        component: () => import("../views/UserCenter/JobPreview"),
      },
      {
        path: "/RecruiterCenter", //招聘主页
        name: "RecruiterCenter",
        component: () => import("../views/UserCenter/RecruiterCenter/index"),
        children: [
          {
            path: "Rcindex",
            name: "Rcindex",
            meta: {
              title: "我的主页",
              index: 0,
            },
            component: () =>
              import("../views/UserCenter/RecruiterCenter/Rcindex"),
          },
          {
            path: "Rcpublish/:current",
            name: "Rcpublish",
            meta: {
              title: "我的发布",
              index: 1,
            },
            component: () =>
              import("../views/UserCenter/RecruiterCenter/Rcpublish"),
          },
          {
            path: "Message/:current",
            name: "Message",
            meta: {
              title: "消息中心",
              index: 2,
            },
            component: () =>
              import("../views/UserCenter/RecruiterCenter/Message"),
          },
          {
            path: "SecuritySettings",
            name: "SecuritySettings",
            meta: {
              title: "安全设置",
              index: 3,
            },
            component: () =>
              import("../views/UserCenter/RecruiterCenter/SecuritySettings"),
          },
          {
            path: "AccountBinding",
            name: "AccountBinding",
            meta: {
              title: "账户绑定",
              index: 4,
            },
            component: () =>
              import("../views/UserCenter/RecruiterCenter/AccountBinding"),
          },
        ],
      },
      {
        path: "/ReviseInformation",
        name: "ReviseInformation", //招聘方修改信息
        component: () =>
          import("../views/UserCenter/RecruiterCenter/ReviseInformation"),
      },
      // {
      //   path: '/JobReviseInformation',  //求职者修改信息
      //   name: 'JobReviseInformation',
      //   component: () => import('../views/UserCenter/JobseekersCenter/JobReviseInformation'),
      // }
    ],
  },
  {
    path: "/Enroll", //注册
    name: "Enroll",
    component: () => import("../views/Login/Enroll"),
  },
  {
    path: "/LoginMain",
    name: "LoginMain",
    component: () => import("../views/Login/LoginMain"),
  },
  {
    path: "/UserPrivacy", //用户协议
    name: "UserPrivacy",
    component: () => import("../views/Login/UserPrivacy"),
  },
  {
    path: "/RCenroll", //招聘方注册
    name: "RCenroll",
    component: () => import("../views/Login/RCenroll"),
  },
];

const router = new VueRouter({
  routes,
});
// 路由前置

let writhPath = [
  "/JobseekersCenter/JobInformation",
  "/JobseekersCenter/Collection/JobAnnouncements",
  "/JobseekersCenter/JobMessage",
  "/JobseekersCenter/Security",
  "/JobseekersCenter/ReviseJobInformation",
  "/RecruiterCenter/Rcindex",
  "/RecruiterCenter/Rcpublish",
  "/RecruiterCenter/Message",
  "/RecruiterCenter/SecuritySettings",
  "/PostJob",
];
router.beforeEach((to, from, next) => {
  let result = writhPath.findIndex((item) => {
    return to.path == item;
  });
  console.log(Myvue, "Myvue");
  if (result !== -1) {
    if (!document.cookie) {
      Myvue.$bus.$emit("loginee");
      return;
    }
  }
  // 让页面回到顶部
  document.documentElement.scrollTop = 0;
  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
  if (to.path === "/LoginMain") {
    Myvue.$bus.$emit("showWrap");
    next({ params: { redirect: from.fullPath } });
  } else {
    if (Myvue) {
      Myvue.$bus.$emit("showWrap");
    }
    next();
  }
});

// router.beforeEach((to, from, next) => {
//   window.scrollTo(0, 0);
//   if (to.path === "/LoginMain") {
//     next({ params: { redirect: from.fullPath } });
//   } else {
//     next();
//   }
// });

//路由后置
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

export default router;
