const login = {
  namespaced: true,
  state: {
    token: "",
    token2: "",
  },
  mutations: {
    setToken(state, token) {
      console.log("login", token);
      state.token = token;
    },
    setToken2(state, token) {
      state.token2 = token;
    },
  },
  actions: {},
  getters: {},
};
export default login;
