import Vue from 'vue'
import Vuex from 'vuex'
import login from './login'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    islogin:false,
  },
  getters: {
  },
  mutations: {
    chancelogin(state,e){
      state.islogin = e ;
    }
  },
  actions: {
  },
  modules: {
    login
  }
})

