<template>
  <div id="hzkFN">
    <div class="top">
      <p><span @click="nav('home')">首页</span><span>|</span>
        <span @click="nav('RecommendDirectly')">公告</span><span>|</span>
        <span @click="nav('Universitypage')">单位</span><span>|</span>
        <span @click="nav('TalentInformation')">人才资讯</span><span>|</span>
        <span @click="nav('CityDetail')">地区政策</span>
      </p>
      <p>Copyright © <a style="color: rgb(242, 242, 242);">www.job.xsbg.com</a>, All Rights Reserved. 提供高校及科研单位最新招聘信息</p>
    </div>
    <div>

      <div style="width:155px"></div>

      <!-- <img src="../assets/img/wAboutlog.png" alt="" height="48"> -->

      <!-- <img src="../assets/img/wAboutlog.png" alt="" height="36"> -->

    </div>
  </div>
</template>

<script>
export default {
  name: "FooterNav",
  methods: {
    nav(value) {
      this.$router.push({ name: value }).catch(err => { })
    },
    tohome() {
      this.$router.push('/');
    },
  }
}
</script>

<style scoped lang="less">
#hzkFN {
  width: 100%;
  min-height: 150px;
  max-height: 150px;
  background-color: rgba(51, 51, 51, 1);
  /* footer的高度一定要是固定值*/
  display: flex;
  justify-content: center;
  align-items: center;

  // margin-top: 20px;
  .top {
    margin-right: 530px;

    p {
      text-align: left;
      color: rgb(242, 242, 242);

      &:first-child {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      &:last-child {
        font-size: 12px;
        font-weight: 650;
      }

      span:nth-child(n) {
        cursor: pointer;

        &:hover {
          color: #428bf9;
        }
      }

      span:nth-child(2n) {
        margin: 0 10px;
      }
    }

    ;
  }
}

//移动端

@media(max-width:576px) {
  .top {
    margin: 0 auto !important;
    padding-left: 20px;
  }
}</style>